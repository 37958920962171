import React from 'react'
import { GalleryWrapper } from '../assets/styles'
import { Photos } from '../components'
import SEO from '../components/seo'

const Gallery = () => {
    return (
        <>
            <SEO title="Gallery" />
            <GalleryWrapper>
                <Photos />
            </GalleryWrapper>
        </>
    );
}

export default Gallery